const DEFAULT_BOOLEAN_VALUES = Object.freeze({
  allowAddContacts: true,
  allowCC: true,
  allowClose: true,
  allowDecline: null,
  allowDownload: true,
  allowRedirect: true,
  allowRemoveContacts: true,
  allowShareLink: true,
  iframeRedirect: false,
  showHeader: true,
  showSendButton: true,
  signatureDefaultName: true
})

export default DEFAULT_BOOLEAN_VALUES
