export const API_APP_PREFERENCE_STYLES_ATTRIBUTES = {
  primaryColor: {
    label: 'Select an Accent Color',
    details: 'Primary color is used as a background for buttons, color for special text, etc.',
    defaultValue: '#51B6C4'
  },
  buttonTextColor: {
    label: 'Select a color for Button Labels',
    details: 'The color of button labels must be in contrast with the accent color.',
    defaultValue: '#FFFFFF'
  },
  linkTextColor: {
    label: 'Select a color for Links',
    details: 'By default, links will be displayed in classic blue.',
    defaultValue: '#1A0DAB'
  },
  buttonsBorderRadius: {
    defaultValue: 25
  }
}
