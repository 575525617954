import { attemptExternalRedirection } from 'lib/helpers/attempt_external_redirection'
import setApiAppStyles from 'lib/helpers/set_api_app_styles'
import { initializeUrl } from 'lib/helpers/url'
import DEFAULT_BOOLEAN_VALUES from 'constants/embedded'
import EMBEDDED_EVENT_TYPES from 'constants/embedded_event_types'

const LISTENERS = Object.freeze({
  message: 'message',
  resize: 'resize'
})

const ids = Object.freeze({
  closeBtn: 'SignWell-Modal-Embedded-Close',
  closeConfirmationModal: 'SignWell-Modal-Embedded-CloseConfirmationModal',
  closeConfirmationModalActions: 'SignWell-Modal-Embedded-CloseConfirmationModal-Actions',
  closeConfirmationModalCancel: 'SignWell-Modal-Embedded-CloseConfirmationModal-Cancel',
  closeConfirmationModalConfirm: 'SignWell-Modal-Embedded-CloseConfirmationModal-Confirm',
  closeConfirmationModalContent: 'SignWell-Modal-Embedded-CloseConfirmationModal-Content',
  closeConfirmationModalTitle: 'SignWell-Modal-Embedded-CloseConfirmationModal-Title',
  closeConfirmationModalOverlay: 'SignWell-Modal-Embedded-CloseConfirmationModal-Overlay',
  iframe: 'SignWell-Embedded-Iframe',
  iframeContainer: 'SignWell-Embedded-Iframe-Container',
  loader: 'SignWell-Embedded-Loader',
  loaderCircle: 'SignWell-Embedded-LoaderCircle',
  modalEl: 'SignWell-Modal-Embedded',
  modalContainerEl: 'SignWell-Modal-Embedded-IframeContainer',
  modalOverlay: 'SignWell-Modal-Embedded-Overlay'
})

const smallDeviceBreakpoint = '768px'

const eventTypes = Object.freeze({
  addLoader: EMBEDDED_EVENT_TYPES.ADD_LOADER,
  clickedContinueBtn: EMBEDDED_EVENT_TYPES.CLICKED_CONTINUE_BTN,
  closed: EMBEDDED_EVENT_TYPES.DOCUMENT_CLOSED,
  completeSelfSigning: EMBEDDED_EVENT_TYPES.DOCUMENT_COMPLETE_SELF_SIGNING,
  completed: EMBEDDED_EVENT_TYPES.DOCUMENT_COMPLETED,
  declined: EMBEDDED_EVENT_TYPES.DOCUMENT_DECLINED,
  documentLoaded: EMBEDDED_EVENT_TYPES.DOCUMENT_LOADED,
  error: EMBEDDED_EVENT_TYPES.DOCUMENT_ERROR,
  notifyRedirectionError: EMBEDDED_EVENT_TYPES.NOTIFY_REDIRECTION_ERROR,
  notifyRedirectionStart: EMBEDDED_EVENT_TYPES.NOTIFY_REDIRECTION_START,
  removeLoader: EMBEDDED_EVENT_TYPES.REMOVE_LOADER,
  resizing: EMBEDDED_EVENT_TYPES.RESIZING,
  setAttr: EMBEDDED_EVENT_TYPES.SET_ATTR,
  setId: EMBEDDED_EVENT_TYPES.SET_ID
})

const views = Object.freeze({
  documentBuilder: 'document_builder',
  editFiles: 'edit_files',
  editRecipients: 'edit_recipients',
  recipientSide: 'recipient_side',
  templateBuilder: 'template_builder'
})

const embeddedRequestingViews = [views.documentBuilder, views.templateBuilder, views.editFiles, views.editRecipients]

const styles = document.createTextNode(`
  #${ids.iframeContainer} { position: relative; width: 100%; height: 100%; }

  #${ids.modalEl} { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; }

  #${ids.modalContainerEl} {
    position: relative; top: 50%; left: 50%; z-index: 100;
    width: 90%; height: 90%; background-color: #FFFFFF;
    -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
  }

  @media screen and (max-width: ${smallDeviceBreakpoint}) {
    #${ids.modalContainerEl} {
      width: 100%; height: 100%; top: unset; left: unset;
      -webkit-transform: unset; -ms-transform: unset; transform: unset;
    }
  }

  #${ids.closeBtn} {
    position: absolute; z-index: 100001; top: -0.75em; right: -0.75em; overflow: hidden; width: 1em;
    height: 1em; padding: 0; appearance: none; -webkit-appearance: none; background-color: #aaa;
    border: 6px solid #aaa; border-radius: 40px; cursor: pointer; color: #fff; transition: .2s all;
  }

  @media screen and (max-width: ${smallDeviceBreakpoint}) {
    #${ids.closeBtn} { display: none !important }
  }

  #${ids.closeBtn}:hover {
    border-color: #323131; background: #323131; transition: .2s all;
  }

  #${ids.closeBtn}::before, #${ids.closeBtn}::after {
    content: ''; position: absolute; height: 2px; width: 100%;
    top: 50%; left: 0; margin-top: -1px; background-color: #fff;
  }

  #${ids.closeBtn}::before {
    -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);
  }
  #${ids.closeBtn}::after {
    -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(-45deg);
  }

  #${ids.modalOverlay}, #${ids.closeConfirmationModalOverlay} {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.2;
  }

  #${ids.closeConfirmationModalOverlay} { z-index: 1; }

  #${ids.closeConfirmationModal} {
    display: none; width: 100%; height: 100%; position: absolute; top: 0; left: 0;
    font-family: "proxima-nova", "Proxima Nova", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #${ids.closeConfirmationModalContent} {
    position: relative; z-index: 2; top: 1em; left: 50%; padding: 6px 12px; width: 80%; max-width: 500px;
    border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.4); background-color: #F7F7F7;
    -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%); transform: translateX(-50%);
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 20%); -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  }

  #${ids.closeConfirmationModalTitle} {
    width: 100%; margin: 10px 0; color: #5F5E5E; line-height: 1.5; text-align: center; font-size: 18px; font-weight: 500;
  }

  #${ids.closeConfirmationModalActions} { padding: 20px; text-align: center; }

  #${ids.closeConfirmationModalCancel}, #${ids.closeConfirmationModalConfirm} {
    display: inline-block; padding: 0.5em 2em; margin: 0 5px; border: none; outline: none;
    border-radius: var(--SignWell-Embedded-buttons-border-radius, 25px); text-align: center;
    text-decoration: none; line-height: 180%; font-size: 14px; font-weight: 400; cursor: pointer;
  }

  #${ids.closeConfirmationModalCancel} {
    border: 1px solid #D2D2D2; background: rgba(255, 255, 255, 0) !important; color: #575757;
  }

  #${ids.closeConfirmationModalCancel}:hover, #${ids.closeConfirmationModalCancel}:focus {
    border: 1px solid var(--SignWell-Embedded-primary-color-darker, #0E515A);
    color: var(--SignWell-Embedded-primary-color-darker, #0E515A);
    background: rgba(255, 255, 255, 0);
  }

  #${ids.closeConfirmationModalConfirm} { background: var(--SignWell-Embedded-primary-color, #51B6C4); color: #FFF; }

  #${ids.closeConfirmationModalConfirm}:hover, #${ids.closeConfirmationModalConfirm}:focus {
    background: var(--SignWell-Embedded-primary-color-lighter, #64C8D6);
  }

  #${ids.loader} {
    position: absolute; top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
  }

  #${ids.loaderCircle}, #${ids.loaderCircle}:after {
    border-radius: 50%; width: 10em; height: 10em;
  }

  #${ids.loaderCircle} {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(81, 182, 196, 0.2);
    border-right: 1.1em solid rgba(81, 182, 196, 0.2);
    border-bottom: 1.1em solid rgba(81, 182, 196, 0.2);
    border-left: 1.1em solid var(--SignWell-Embedded-primary-color-light, #49A3AF);
    -webkit-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); }
  }
  @keyframes load8 {
    0% { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); }
  }
`)

const createElWithId = (id, tagName = 'div') => {
  const tag = document.createElement(tagName)
  tag.id = id
  return tag
}

const dispatchEmbedderEvent = (eventType, payload, iframeEl) =>
  iframeEl.contentWindow.postMessage({ type: eventType, payload }, '*')

const logRedirectionError = (payload, iframeEl) => () =>
  dispatchEmbedderEvent(eventTypes.notifyRedirectionError, payload, iframeEl)

const logRedirectionStart = (payload, iframeEl) => () =>
  dispatchEmbedderEvent(eventTypes.notifyRedirectionStart, payload, iframeEl)

// START OF TYPE-CHECKING HELPERS
const isString = v => typeof v === 'string'
const isBoolean = v => typeof v === 'boolean'
const isEmpty = v => [null, undefined].includes(v)
const isFunction = v => typeof v === 'function'
const isValidBoolean = v => isBoolean(v) || isEmpty(v)
const isValidString = v => isEmpty(v) || isString(v)
// END OF TYPE-CHECKING HELPERS

const START_VALUES = ['document_view', 'edit_recipients', 'edit_files']
const TYPE_VALUES = ['request_signature', 'sign_and_share']
const STRING_KEYS = ['containerId']
const BOOLEAN_KEYS = [
  'allowAddContacts',
  'allowCC',
  'allowClose',
  'allowDecline',
  'allowDownload',
  'allowRedirect',
  'allowRemoveContacts',
  'allowShareLink',
  'iframeRedirect',
  'showHeader',
  'showSendButton',
  'signatureDefaultName'
]

const PAYLOAD_KEYS_METHODS = Object.freeze(BOOLEAN_KEYS.reduce((obj, key) => {
  obj[key] = key
  return obj
}, { isEmbeddedModal: 'containerIsModal', isLargeDevice: '_isLargeDevice' }))

// TODO: Turn some methods into private methods with the "#methodName" syntax
class SignWellEmbed {
  constructor (params) {
    const { url, events = {} } = params
    if (!url) throw new Error('Missing Url')

    this.events = events
    this.iframeLoaded = false
    this.documentCompleted = false
    this.error = false
    this.allowClose = DEFAULT_BOOLEAN_VALUES.allowClose
    this.allowRemoveContacts = DEFAULT_BOOLEAN_VALUES.allowRemoveContacts
    this.allowRedirect = DEFAULT_BOOLEAN_VALUES.allowRedirect
    this.containerId = null
    this.iframeRedirect = DEFAULT_BOOLEAN_VALUES.iframeRedirect
    this.errorEvent = isFunction(events.error) ? events.error : () => {}
    try {
      STRING_KEYS.forEach(key => this._validateAndSetString(params, key))
      BOOLEAN_KEYS.forEach(key => this._validateAndSetBoolean(params, key))
      this._validateAndSetUrl(params)
      this._validateAndSetRedirectionUrls(params)
    } catch (e) {
      this.errorEvent({ errorCode: `invalid_${this.invalidAttribute}` })
      throw e
    }
  }

  _validateAndSetBoolean = (params, key) => this._validateAndSetValue(params, key, isValidBoolean)

  _validateAndSetString = (params, key) => this._validateAndSetValue(params, key, isValidString)

  _validateAndSetValue = (params, key, validator) => {
    const { [key]: value = DEFAULT_BOOLEAN_VALUES[key] } = params
    this.invalidAttribute = key
    if (!validator(value)) throw new Error(`invalid "${key}" value: `, value)
    this[key] = value
  }

  _validateAndSetUrl = params => {
    const { url, start = null, type = null } = params

    this.invalidAttribute = 'url'
    let parsedUrl = initializeUrl(url)
    parsedUrl.searchParams.set('signwell_embedded_iframe', '1')
    this.invalidAttribute = 'start'
    parsedUrl = this._addValueFromSet(parsedUrl, start, 'start', START_VALUES)
    this.invalidAttribute = 'type'
    parsedUrl = this._addValueFromSet(parsedUrl, type, 'type', TYPE_VALUES)

    this.url = parsedUrl.toString()
  }

  _addValueFromSet = (parsedUrl, value, attribute, validValues) => {
    if (value === null) return parsedUrl

    value = value.toLowerCase()
    if (validValues.includes(value)) { parsedUrl.searchParams.set(attribute, value); return parsedUrl }

    throw new Error(`Invalid "${attribute}" value: ${value}.\nValid values are: ${validValues.join(', ')}.`)
  }

  _validateAndSetRedirectionUrls = params => {
    const { declineRedirectionUrl = null, redirectionUrl = null, requestingRedirectUrl = null } = params

    this.invalidAttribute = 'declineRedirectionUrl'
    if (declineRedirectionUrl) this.declineRedirectionUrl = initializeUrl(declineRedirectionUrl).toString()
    this.invalidAttribute = 'redirectionUrl'
    if (redirectionUrl) this.redirectionUrl = initializeUrl(redirectionUrl).toString()
    this.invalidAttribute = 'requestingRedirectUrl'
    if (requestingRedirectUrl) this.requestingRedirectUrl = initializeUrl(requestingRedirectUrl).toString()
  }

  _addSignWellEventsListener = () => {
    window.addEventListener(LISTENERS.message, this._messages)
  }

  _messages = event => {
    const { data: { type, payload } } = event
    switch (type) {
      case eventTypes.setId:
        this._setIdAndView(payload); break
      case eventTypes.completed:
        this._completedHandler(payload); break
      case eventTypes.declined:
        this._declinedHandler(payload); break
      case eventTypes.clickedContinueBtn:
        this._continueBtnHandler(payload); break
      case eventTypes.closed:
        this._closedHandler(); break
      case eventTypes.documentLoaded:
        this._documentLoadedHandler(); break
      case eventTypes.error:
        this._errorHandler(payload); break
      case eventTypes.removeLoader:
        this._removeLoader(); break
      case eventTypes.addLoader:
        this._addLoader(); break
    }
  }

  _iframeLoadedHandler = () => {
    clearTimeout(this.errorTimeout)
    dispatchEmbedderEvent(eventTypes.setAttr, this._embedderSetAttrEventPayload, this.iframe)
    if (this.error || this.iframeLoaded) return

    this.iframeLoaded = true
    // eslint-disable-next-line no-unused-expressions
    this.events.iframeLoaded?.(this._commonPayload)
  }

  _addLoader = () => {
    this.loader && (this.loader.style.display = 'block')
    this.iframe.style.display = 'none'
  }

  _removeLoader = () => {
    this.loader && (this.loader.style.display = 'none')
    this.iframe.style.display = 'block'
  }

  get _embedderSetAttrEventPayload () {
    const payload = Object.keys(PAYLOAD_KEYS_METHODS).reduce((obj, key) => {
      obj[key] = this[PAYLOAD_KEYS_METHODS[key]]
      return obj
    }, {})
    payload.isEmbedded = true
    if (this.redirectionUrl) payload.redirectionUrl = this.redirectionUrl
    if (this.requestingRedirectUrl) payload.requestingRedirectUrl = this.requestingRedirectUrl
    if (this.declineRedirectionUrl) payload.declineRedirectionUrl = this.declineRedirectionUrl
    return payload
  }

  get _isEmbeddedRequesting () { return embeddedRequestingViews.includes(this.view) }

  _setIdAndView = ({ id, apiAppStyles = {}, newEditUrl = '', view }) => {
    this.documentId = id
    this.newEditUrl = newEditUrl // allows the parent to get the new embeddedEditUrl, in case they need it
    this.view = view // this tells which app we're rendering
    if (this.allowClose && this.closeBtn) this.closeBtn.style.display = 'block'
    if (Object.keys(apiAppStyles)) {
      setApiAppStyles({ apiAppStyles, baseIdentifier: 'SignWell-Embedded', baseElement: this.iframeContainer })
    }
  }

  // self-signed documents have { redirect: false } since redirections don't apply to self-sign mode
  _completedHandler = ({ redirect = true, ...payload }) => {
    this.documentCompleted = true
    // eslint-disable-next-line no-unused-expressions
    this.events.completed?.({ ...this._commonPayload, url: payload.url })
    this._shouldRedirect({ redirect }) && this._redirectTo(payload)
  }

  _declinedHandler = ({ declineReason, ...payload }) => {
    // eslint-disable-next-line no-unused-expressions
    this.events.declined?.({ ...this._commonPayload, declineReason, url: payload.url })
    this._shouldRedirect({ redirect: true }) && this._redirectTo(payload)
  }

  _shouldRedirect = ({ redirect = true }) => this.allowRedirect && redirect && !this.iframeRedirect

  _redirectTo = payload => {
    const args = { ...payload, embedded: true }
    attemptExternalRedirection(args, logRedirectionStart(args, this.iframe), logRedirectionError(args, this.iframe))
  }

  _continueBtnHandler = payload => this._redirectTo({ ...payload, clickedContinueBtn: true })

  get canCloseWithoutConfirmation () { return this.documentCompleted || this.error }

  _closedHandler = () =>
    this.canCloseWithoutConfirmation ? this._closeIframe() : this._openCloseConfirmationModal()

  _openCloseConfirmationModal = () => {
    this.closeConfirmationModal.style.display = 'block'
  }

  _closeCloseConfirmationModal = () => {
    this.closeConfirmationModal.style.display = 'none'
  }

  _closeIframe = () => {
    // eslint-disable-next-line no-unused-expressions
    this.events.closed?.(this._commonPayload)
    this.close()
  }

  _documentLoadedHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    this.events.documentLoaded?.(this._documentLoadedPayload)
  }

  get _documentLoadedPayload () {
    return this._isEmbeddedRequesting
      ? { ...this._commonPayload, newEditUrl: this.newEditUrl }
      : this._commonPayload
  }

  _errorHandler = ({ id, errorCode, errorMessage }) => {
    this.error = true
    this.errorEvent({ id, errorCode, errorMessage })
    this.closeBtn.style.display = 'block'
  }

  get _commonPayload () { return { id: this.documentId } }

  get container () {
    if (!this.containerEl) this._setContainerEl()
    return this.containerEl
  }

  get containerIsModal () { return this.container.id === ids.modalContainerEl }

  _setContainerEl = () => {
    if (this.containerId) this.containerEl = document.getElementById(this.containerId)
    if (this.containerEl) return // The provided containerId exists

    this._createAndMountModal()
  }

  get closeBtn () {
    if (!this.closeBtnEl) {
      this.closeBtnEl = createElWithId(ids.closeBtn)
      this.closeBtnEl.onclick = this._closedHandler
      this.closeBtnEl.style.display = 'none'
    }

    return this.closeBtnEl
  }

  get cancelBtn () {
    if (!this.cancelBtnEl) {
      this.cancelBtnEl = createElWithId(ids.closeConfirmationModalCancel, 'button')
      this.cancelBtnEl.onclick = this._closeCloseConfirmationModal
      this.cancelBtnEl.innerText = 'Cancel'
    }

    return this.cancelBtnEl
  }

  get confirmCancelBtn () {
    if (!this.confirmCancelBtnEl) {
      this.confirmCancelBtnEl = createElWithId(ids.closeConfirmationModalConfirm, 'button')
      this.confirmCancelBtnEl.onclick = this._closeIframe
      this.confirmCancelBtnEl.innerText = 'Close'
    }

    return this.confirmCancelBtnEl
  }

  get loader () {
    if (!this.loaderEl) {
      this.loaderEl = createElWithId(ids.loader)
      this.loaderEl.appendChild(createElWithId(ids.loaderCircle))
    }

    return this.loaderEl
  }

  get iframeContainer () {
    if (!this.iframeContainerEl) this.iframeContainerEl = createElWithId(ids.iframeContainer)

    return this.iframeContainerEl
  }

  _createAndMountCloseConfirmationModal = () => {
    this.closeConfirmationModalEl = createElWithId(ids.closeConfirmationModal)
    const overlay = createElWithId(ids.closeConfirmationModalOverlay)
    const content = createElWithId(ids.closeConfirmationModalContent)
    const title = createElWithId(ids.closeConfirmationModalTitle, 'h1')
    title.innerText = 'Are you sure you want to close this dialog?'
    const actions = createElWithId(ids.closeConfirmationModalActions)
    actions.appendChild(this.cancelBtn)
    actions.appendChild(this.confirmCancelBtn)
    content.appendChild(title)
    content.appendChild(actions)
    this.closeConfirmationModalEl.appendChild(overlay)
    this.closeConfirmationModalEl.appendChild(content)
  }

  get closeConfirmationModal () {
    if (!this.closeConfirmationModalEl) this._createAndMountCloseConfirmationModal()

    return this.closeConfirmationModalEl
  }

  _createAndMountModal = () => {
    this.containerEl = createElWithId(ids.modalContainerEl)
    this.modalOverlay = createElWithId(ids.modalOverlay)
    this.modalEl = createElWithId(ids.modalEl)
    this.containerEl.appendChild(this.closeBtn)
    this.modalEl.appendChild(this.containerEl)
    this.modalEl.appendChild(this.modalOverlay)
    document.body.appendChild(this.modalEl)
  }

  _appendStyles = () => {
    if (this.styleTag) return

    this.styleTag = document.createElement('style')
    this.styleTag.appendChild(styles)
    document.getElementsByTagName('head')[0].appendChild(this.styleTag)
  }

  _renderIframe = () => {
    if (this.iframe) return

    this.iframe = document.createElement('iframe')
    this.iframe.onload = this._iframeLoadedHandler
    this.iframe.id = ids.iframe
    this.iframe.allow = 'clipboard-read; clipboard-write'
    this.iframe.width = '100%'
    this.iframe.height = '100%'
    this.iframe.minHeight = '400px'
    this.iframe.src = this.url
    this.iframe.style.border = '1px solid #CFCFCF'
    this.iframe.style.display = 'none'
    this.iframeContainer.appendChild(this.closeConfirmationModal)
    this.iframeContainer.appendChild(this.iframe)
    this.iframeContainer.appendChild(this.loader)
    this.container.appendChild(this.iframeContainer)
    this.errorTimeout = setTimeout(() => this._errorHandler({ errorCode: 'request_timed_out' }), 20000)
    window.addEventListener(LISTENERS.resize, this._resizing)
  }

  _resetInstanceState = () => {
    this.documentCompleted = false
    this.error = false
    this.iframe = null
    this.iframeContainerEl = null
    this.iframeLoaded = null
    this.modalEl = null
    this.containerEl = null
    this.modalOverlay = null
    this.closeBtnEl = null
    this.closeConfirmationModalEl = null
    this.loaderEl = null
    window.removeEventListener(LISTENERS.resize, this._resizing)
    window.removeEventListener(LISTENERS.message, this._messages)
  }

  open = () => {
    this._addSignWellEventsListener()
    this._appendStyles()
    this._renderIframe()
  }

  close = () => {
    this.containerIsModal && this.modalEl && this.modalEl.remove()
    // eslint-disable-next-line no-unused-expressions
    this.iframeContainer?.remove()
    this._resetInstanceState()
  }

  completeEmbeddedSelfSigning = () => {
    dispatchEmbedderEvent(eventTypes.completeSelfSigning, {}, this.iframe)
  }

  _resizing = () => dispatchEmbedderEvent(eventTypes.resizing, this._embedderResizingEventPayload, this.iframe)

  get _embedderResizingEventPayload () { return { isLargeDevice: this._isLargeDevice } }

  get _isLargeDevice () {
    return !window.matchMedia(`(max-width: ${smallDeviceBreakpoint})`).matches
  }
}

window.SignWellEmbed = window.SignWellEmbed || SignWellEmbed
