export const attemptExternalRedirection = (args = {}, eventLogger, errorHandler) => {
  const { url } = args
  if (!url) return

  setTimeout(() => errorHandler(args), 3000)
  eventLogger(args)
  setTimeout(() => {
    // delay redirection to ensure that events (from eventLogger and from the embedder) have been fired
    window.location.assign(url)
  }, 300)
}
