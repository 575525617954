import { shadeColor } from 'lib/helpers/colors'

import { API_APP_PREFERENCE_STYLES_ATTRIBUTES } from 'constants/api_app_preferences'

const {
  primaryColor: { defaultValue: defaultPrimaryColor },
  buttonTextColor: { defaultValue: defaultButtonTextColor },
  linkTextColor: { defaultValue: defaultLinkTextColor },
  buttonsBorderRadius: { defaultValue: defaultButtonsBorderRadius }
} = API_APP_PREFERENCE_STYLES_ATTRIBUTES

export const buildApiAppStyles = ({ apiAppStyles, baseIdentifier = 'api-app' }) => {
  const {
    buttonsBorderRadius = defaultButtonsBorderRadius,
    buttonTextColor = defaultButtonTextColor,
    linkTextColor = defaultLinkTextColor,
    primaryColor = defaultPrimaryColor
  } = apiAppStyles

  const styles = {}

  if (!isNaN(buttonsBorderRadius) && buttonsBorderRadius !== defaultButtonsBorderRadius) {
    styles[`--${baseIdentifier}-buttons-border-radius`] = `${buttonsBorderRadius}px`
  }
  if (buttonTextColor && buttonTextColor !== defaultButtonTextColor) {
    styles[`--${baseIdentifier}-button-text-color`] = buttonTextColor
  }
  if (linkTextColor && linkTextColor !== defaultLinkTextColor) {
    styles[`--${baseIdentifier}-link-text-color`] = linkTextColor
    styles[`--${baseIdentifier}-link-text-color-dark`] = shadeColor(linkTextColor, -20)
  }
  if (primaryColor && primaryColor !== defaultPrimaryColor) {
    styles[`--${baseIdentifier}-primary-color`] = primaryColor
    styles[`--${baseIdentifier}-primary-color-slightly-dark`] = shadeColor(primaryColor, -4)
    styles[`--${baseIdentifier}-primary-color-dark`] = shadeColor(primaryColor, -10)
    styles[`--${baseIdentifier}-primary-color-darkish`] = shadeColor(primaryColor, -20)
    styles[`--${baseIdentifier}-primary-color-darker`] = shadeColor(primaryColor, -30)
    styles[`--${baseIdentifier}-primary-color-darkest`] = shadeColor(primaryColor, -50)
    styles[`--${baseIdentifier}-primary-color-light`] = shadeColor(primaryColor, 15)
    styles[`--${baseIdentifier}-primary-color-lighter`] = shadeColor(primaryColor, 30)
  }

  return styles
}

const setApiAppStyles = ({ apiAppStyles, baseIdentifier = 'api-app', baseElement = document.documentElement }) => {
  const styles = buildApiAppStyles({ apiAppStyles, baseIdentifier })
  Object.entries(styles).forEach(([key, value]) => baseElement.style.setProperty(key, value))
}

export default setApiAppStyles
